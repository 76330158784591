.DeleteUserAccountWebPage {
    direction: rtl;
    font-family: 'Almarai';
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.7;
    color: #2c2929;
}

.DeleteUserAccountWebPageTitle {
    font-weight: 600;
    font-size: 1.5rem;
}

.DeleteUserAccountWebPageDeleteBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #ff0000;
    border: 1px solid #ff0000;
    color: #fff;
    border-radius: 0.375rem;
    padding: 0.375rem 0.75rem;
    outline: none;
}

.DeleteUserAccountWebPageDeleteBtn .recycle-bin-icon,
.DeleteUserAccountWebPageConfirmationMsgContainer .confirm-icon,
.DeleteUserAccountWebPageConfirmationMsgContainer .cancel-icon,
.DeleteUserAccountWebPageFinalConfirmationMsg .confirm-icon {
    margin-left: 0.3rem;
}

.DeleteUserAccountWebPageConfirmationMsgContainer {
    background-color: #f0eeee;
    border-radius: 0.375rem;
    padding: 2rem 2rem;
    text-align: center;
}

.DeleteUserAccountWebPageConfirmDeletionBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #00bd85;
    border: 1px solid #00bd85;
    color: #fff;
    border-radius: 0.375rem;
    /* padding: 0.375rem 0.75rem; */
    outline: none;
}

.DeleteUserAccountWebPageCancelDeletionBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #ff0000;
    border: 1px solid #ff0000;
    color: #fff;
    border-radius: 0.375rem;
    /* padding: 0.375rem 0.75rem; */
    outline: none;
}

.DeleteUserAccountWebPageFinalConfirmationMsg {
    text-align: center;
    color: #00bd85;
    font-weight: bold;
}