.termsAndConditionsWebPage {
    direction: rtl;
    font-family: 'Almarai';
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.7;
    color: #2c2929;
    text-align: justify;
}

.termsAndConditionsWebPageTitle {
    font-weight: 600;
    font-size: 1.5rem;
    color: #00BD85;
}

.termsAndConditionsWebPageSubtitle {
    font-weight: 600;
    font-size: 1.2rem;
    color: #00BD85;
    margin-top: -0.5rem;
}

.termsAndConditionsWebPage li {
    padding-bottom: 0.5rem;
    padding-top: 0.5rem;
}

.termsAndConditionsWebPage ol li {
    font-weight: 500 !important;
}

.termsAndConditionsWebPage ul li {
    font-weight: 600;
}

.termsAndConditionsWebPage hr {
    opacity: 1;
    color: #00BD85;
    height: 2px;
}

.termsAndConditionsWebPage mark {
    background-color: mark;
    color: marktext;
}

/* web/medium devices start */
@media (min-width: 768px) {

    .terms-and-condition-web-Page-logo {
        width: 10%;
    }

}
/* web/medium devices end */


/* mobile devices start */
@media (max-width: 768px) {

    .terms-and-condition-web-Page-logo {
        width: 17%;
    }
}
/* mobile devices end */