.agel-footer-upper-part{
    background: transparent linear-gradient(254deg, #00BD85 0%, #393636 100%) 0% 0% no-repeat padding-box;
}

.agel-footer-upper-part-info-part1, .agel-footer-upper-part-info-part2{
    color: #FFFFFF;
    font-weight: normal;
    direction: rtl;
    text-align: justify;
}

.agel-footer-upper-part-info-part1-span1-underlined, .agel-footer-upper-part-info-part2-span1-underlined{
    border-bottom:1.7px solid #fff;
    padding-bottom:1.2px;
    margin-left:2.5px
}

.agel-footer-upper-part-info-part1-span3-underlined{
    border-bottom: 1.7px solid #fff;
    padding-bottom: 1.2px;
    margin-left: 2.5px;
}

.agel-footer-upper-part-info-border{
    margin-right: 2rem;
    margin-left: 1.5rem;
    border: 1px solid #FFFFFF;
    background-color: #FFFFFF;  /* mmkn n commentha 3ade bs hata5od el cuurentColor el default bta3ha */
}

.footer-fb-icon, .footer-linkedin-icon{
    width: 1.5rem;
    margin: 0.5rem;
}

.footer-fb-icon:hover, .footer-linkedin-icon:hover{
    width: 1.3rem;
    margin-left: 0.7rem;
    opacity: 0.7;
    cursor: pointer;
}


/* web/medium devices start */
@media (min-width: 768px) {

    .agel-footer-upper-part-img{
        width: 40%;
        height: 100%;
    }

    .footer-info-container{
        display: flex;
        justify-content: flex-end;
        padding-left: 15%;
    }

    .agel-footer-upper-part-info-part1-span1, .agel-footer-upper-part-info-part1-span3, .agel-footer-upper-part-info-part2-span1{
        font-size:0.8rem;
    }    

    .agel-footer-upper-part-info-part1-span4{
        line-height: 1rem;
        font-size:0.9rem;
    }
    
    .agel-footer-upper-part-info-part1-span2, .agel-footer-upper-part-info-part2-span2, .agel-footer-upper-part-info-part2-span3{
        font-size:0.9rem;
    }    

    .agel-footer-lower-part{
        background: #232323 ;
        color: #FFFFFF;
        font-size: 0.8rem;
        font-weight: normal;
        padding: 0.5rem;
    }

    .footer-socials-part1{
        display: none;
    }

    .footer-socials-part2{
        display: flex;
    }

    
}

/* web/medium devices start */


/* mobile devices start */
@media (max-width: 768px) {

    .agel-footer-upper-part-img-container{
        text-align: center;
    }

    .agel-footer-upper-part-img{
        width: 50%;
        height: 100%;
    }

    .footer-info-container{
        display: flex;
        justify-content: center;
        /* padding-left: 15%; */
    }

    .agel-footer-upper-part-info-part1-span1, .agel-footer-upper-part-info-part1-span3, .agel-footer-upper-part-info-part2-span1{
        font-size:0.7rem;
    }
    
    .agel-footer-upper-part-info-part1-span4{
        line-height: 1rem;
        font-size:0.8rem;
    }
    
    .agel-footer-upper-part-info-part1-span2, .agel-footer-upper-part-info-part2-span2, .agel-footer-upper-part-info-part2-span3{
        font-size:0.8rem;
    }
    
    .agel-footer-lower-part{
        background: #232323 ;
        color: #FFFFFF;
        font-size: 0.6rem;
        font-weight: normal;
        padding: 0.5rem;
    }

    .footer-socials-part1{
        display: flex;
    }

    .footer-socials-part2{
        display: none;
    }

}

/* mobile devices end */