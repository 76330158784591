.how-to-use-agel {
    padding-top: 6rem;
    padding-bottom: 6rem;
}

.how-to-use-agel-container {
    margin-top: -2.1rem;
    z-index: 999;
}
.how-to-use-agel-card {
    border: 4px solid #C4C4C454;
    background: #FFFFFF;
    border-radius: 42px;
    padding: 0.5rem;
    width: 80%;
    min-height: 9rem;
}


/* web/medium devices start */
@media (min-width: 768px) {

    .how-to-use-agel-title {
        font-weight: 600;
        color: #1B1919;
        font-size: 1.46rem;
    }

    .how-to-use-agel-subtitle {
        font-weight: normal;
        color: #393636;
        font-size: 1.1rem;
        margin-right: 1rem;
        margin-left: 1rem;
        text-align: justify;
        display: grid;
        justify-content: center;
        align-items: center;
    }

    .how-to-use-agel-img {
        width: 60%;
        height: 60%;
    }

    .how-to-use-agel-card:hover {
        border: 4px solid #00BD85;
        background: #FFFFFF;
        border-radius: 42px;
        padding: 0.5rem;
        width: 80%;
        min-height: 9rem;
    }    

    .how-to-use-agel-card-title {
        color: #393636;
        font-weight: bold;
        font-size: 0.9rem;
    }

    .how-to-use-agel-card-subtitle {
        font-weight: normal;
        color: #393636;
        font-size: 0.8rem;
        line-height: 1.4rem;
        padding-left: 1rem;
        padding-right: 1rem;
    }
    

}

/* web/medium devices start */


/* mobile devices start */
@media (max-width: 768px) {

    .how-to-use-agel-title {
        font-weight: 600;
        color: #1B1919;
        font-size: 1rem;
    }

    .how-to-use-agel-subtitle {
        font-weight: normal;
        color: #393636;
        font-size: 0.8rem;
        margin-right: 1rem;
        margin-left: 1rem;
        /* text-align: justify; */
        display: grid;
        justify-content: center;
        align-items: center;
    }

    .how-to-use-agel-img {
        width: 100%;
        height: 100%;
    }

    .how-to-use-agel-card-title {
        color: #393636;
        font-weight: bold;
        font-size: 0.9rem;
    }

    .how-to-use-agel-card-subtitle {
        font-weight: normal;
        color: #393636;
        font-size: 0.8rem;
        line-height: 1.5rem;
        padding-left: 1rem;
        padding-right: 1rem;
    }
}
/* mobile devices end */



/* special case desktop screens */
@media (min-width: 990px){ 
    .how-to-use-agel-middle-card{
        margin-top: -2.5rem;
    }
}
