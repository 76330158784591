.download-app-content-container {
    width: 100%;
    padding-top: 6rem;
    padding-bottom: 6rem;
}





/* web/medium devices start */
@media (min-width: 768px) {
    .download-app-container {
        background-image: url("../../../public/images/download-app.png");
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
        min-height: 100%;
        width: 100%;
        /* background-color: red;
        border: 1px solid; */
        /* margin-bottom: 5rem; */
    }

    .download-app-title {
        font-weight: 600;
        color: #323232;
        font-size: 2.1rem;
    }

    .download-app-subtitle {
        font-weight: normal;
        color: #343434;
        font-size: 1rem;
    }

    .download-app-logo-download-screen {
        width: 8.5rem;
        height: 3.5rem;
        margin-top: 2rem;
    }

    .download-app-logo-download-screen:hover {
        width: 8.5rem;
        height: 3.5rem;
        margin-top: 2rem;
        transform: scale(1.1, 1.1);
        cursor: pointer;
    }

}

/* web/medium devices start */


/* mobile devices start */
@media (max-width: 768px) {
    .download-app-container {
        background-image: url("../../../public/images/Group\ 739.png");
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
        min-height: 100%;
        width: 100%;
        
        /* background-color: red;
        border: 1px solid; */

        /* border: 1px solid; */
        /* margin-bottom: 5rem; */

    }

    .download-app-title {
        font-weight: 600;
        color: #323232;
        font-size: 1.6rem;
    }

    .download-app-subtitle {
        font-weight: normal;
        color: #343434;
        font-size: 0.8rem;
    }

    .download-app-logo-download-screen {
        width: 6.8rem;
        height: 2.8rem;
        margin-top: 0.4rem;
    }

}

/* mobile devices end */