.about-agel-container {
    min-height: 100%;
    max-width: 100%;
}

.about-agel-top-colored-part{
    background-color: #F9F9F9;
    width: 100%;
    height: 10rem;
}
/* .about-agel-container-img{
    margin-top: -6rem;
} */



/* web/medium devices start */
@media (min-width: 768px) {

    .about-agel-img{
        width: 100%;
        height: 100%;
    }

    .about-agel-text-container {
        display: grid;
        justify-content: center;
        align-items: center;
        direction: rtl;
        text-align: center;
        margin-top: 4rem;
        margin-bottom: 4rem;
    }
    
    .about-agel-content-container {
        margin-left: 2rem;
        margin-right: 2rem;
    }

    .about-agel-title {
        font-weight: 600;
        color: #1B1919;
        font-size: 1.8rem;
        margin-bottom: 3rem;
    }

    .about-agel-text {
        font-weight: normal;
        color: #393636;
        font-size: 1.1rem;
    }
}

/* web/medium devices start */


/* mobile devices start */
@media (max-width: 768px) {

    .about-agel-img{
        width: 100%;
        height: 90%;
    }
    
    .about-agel-text-container {
        display: grid;
        justify-content: center;
        align-items: center;
        direction: rtl;
        text-align: center;
        margin-top: -2rem;
        margin-bottom: 1.5rem;
    }
    
    .about-agel-content-container {
        /* margin-left: 1rem;
        margin-right: 1rem; */
    }

    .about-agel-title {
        font-weight: 600;
        color: #1B1919;
        font-size: 1.3rem;
        margin-bottom: 2rem;
    }

    .about-agel-text {
        font-weight: 400;
        color: #393636;
        font-size: 0.8rem;
    }
}

/* mobile devices end */