.main-navbar {
    background: #414141 0% 0% no-repeat padding-box;
    padding-top: 0.3rem;
    padding-bottom: 0.3rem;
    position: fixed;
    width: 100%;
    top: 0%;
    left: 0%;
    right: 0%;
    z-index: 999 !important;
    color: "white" !important;
}

.navbar-light .navbar-toggler-icon {
    background-image: url('../../../public/images/navbarToggler.png');
    /* background-color: #bd9100; */
    /* background-color: red; */
}

.navbar-light .navbar-toggler {
    border: none;
}

.navbar-toggler {
    border: none;
}

.navbar-logo-img {
    width: 5rem;
}

/* .btn-close{
    color: white;
} */
.nav-items {
    font-style: normal;
    font-weight: normal;
    font-size: 0.9rem;
    line-height: 23px;
    color: #F4FFFED9 !important;
    margin-right: 1.5rem;
    opacity: 0.9;
}

.nav-items:hover,
.selectNavItem {
    font-style: normal;
    /* font-weight: bold; */
    font-size: 0.9rem;
    line-height: 23px;
    color: #FFFFFF !important;
    border-bottom: 3px solid #FFFFFF;
    opacity: 1;
    /* border-radius: 3px; */
    /* margin-top: -1rem; */
}


/* when side menu is open */
@media (max-width: 991px) {
    .offcanvas {
        max-width: 60vw;
        background: transparent linear-gradient(202deg, #00BD85 0%, #393636 100%) 0% 0% no-repeat padding-box;
    }

    .offcanvas-body {
        padding: 0%;
        margin: 0%;
    }

    .navbar-nav .nav-link {
        padding-right: 0.8rem;
        margin-top: 1rem;
    }

    .nav-items {
        font-style: normal;
        font-weight: normal;
        font-size: 0.8rem;
        line-height: 23px;
        width: 55%;
        height: 41px;
        border-radius: 32px 0px 0px 32px;
        margin-right: 0%;
        opacity: 1;
        color: #F4FFFED9;
        /* margin-right: 1em; */
    }

    .nav-items:hover,
    .selectNavItem {

        font-style: normal;
        font-weight: normal;
        font-size: 0.8rem;
        line-height: 23px;
        width: 55%;
        height: 41px;
        border-radius: 32px 0px 0px 32px;
        margin-right: 0%;
        opacity: 1;
        background-color: white;
        color: #1D7B5E !important;
    }

}