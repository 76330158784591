.aboutAgelWebPage {
    direction: rtl;
    font-family: 'Almarai';
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.7;
    color: #2c2929;
}

.aboutAgelWebPageTitle {
    font-weight: 600;
    font-size: 1.5rem;
    color: #00BD85;
}

.aboutAgelWebPage hr {
    opacity: 1;
    color: #00BD85;
    height: 2px;
}

.aboutAgelWebPage li {
    padding-bottom: 0.5rem;
    padding-top: 0.5rem;
}


/* web/medium devices start */
@media (min-width: 768px) {

    .about-Agel-Web-Page-logo {
        width: 10%;
    }

}
/* web/medium devices end */


/* mobile devices start */
@media (max-width: 768px) {

    .about-Agel-Web-Page-logo {
        width: 17%;
    }
}
/* mobile devices end */
