.apply-form {
    /* padding-top: 6rem;
    padding-bottom: 6rem; */
    background: #F9F9F9 0% 0% no-repeat padding-box;
}

.apply-form-container {
    padding-left: 7rem;
    padding-right: 7rem;
    padding-top: 8rem;
    padding-bottom: 8rem;


}


.contact-us-title1 {
    font-size: 0.8rem;
    font-weight: 400;
    color: #6E6E6E;
    position: relative;
    max-width: 100px;
    text-align: center;
}

.contact-us-title1::after {
    content: " ";
    height: 0.1rem;
    width: 33px;
    background: #6E6E6E;
    display: block;
    position: absolute;
    top: 50%;
    right: 0;
}


section {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #C4C4C429;
    border-radius: 24px;
    padding: 3rem;
    padding: 3rem;

    /* background-color: #1e1f26; */
  }
  .agel-form-wrapper {
    width: 90%;
    max-width: 1000px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .form-left {
    width: 50%;
    height: 600px;
    background: url(../../../public/images/agel-form-img.svg) no-repeat center / contain;
  }
  .form-right {
    width: 50%;
    min-height: 400px;
    background-color: #303137;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 30px;
    border-radius: 8px;
    color: white;
    margin-right: -60px;
  }
  .form-right h1 {
    font-size: 40px;
    font-weight: lighter;
  }
  .form-right p {
    margin: 20px 0;
    font-weight: 500;
    line-height: 25px;
  }
  .form-right a {
    text-decoration: none;
    text-transform: uppercase;
    background-color: white;
    color: black;
    padding: 20px 30px;
    display: inline-block;
    letter-spacing: 2px;
  }
  
/* web/medium devices start */
@media (min-width: 768px) {
    .contact-us-title2 {
        font-size: 1.7rem;
        font-weight: 600;
        color: #1B1919;
    }

}

/* web/medium devices start */


/* mobile devices start */
@media (max-width: 768px) {
    .contact-us-title2 {
        font-size: 1.3rem;
        font-weight: 600;
        color: #1B1919;
    }

    .agel-form-wrapper {
        flex-direction: column;
        width: 100%;
        margin: 0 20px;
      }
      .form-left {
        width: 100%;
        height: 400px;
      }
      .form-right {
        width: 90%;
        margin: 0;
        margin-top: -100px;
      }
      

}

/* mobile devices end */