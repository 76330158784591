.zammitWebPage {
    direction: rtl;
    font-family: 'Almarai';
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.7;
    color: #2c2929;
    text-align: justify;
}

.zammit-Web-Page-logo {
    width: 40%;
}

.zammit-header,
.zammit-section {
    margin-top: 1rem;
    font-weight: 900;

}

.zammit-list li {
    margin-bottom: 0.8rem;
    font-weight: 500;
}
.about-zammit-link{
    display: inline;
    text-align: right;
}

.about-zammit-link a {
    margin: 0 0.4rem 0 0;
    border-radius: 5px;
    padding: 0.1rem 0.3rem;
    background-image: linear-gradient(to right, #50f3da , #f4f450);
}

.zammit-footer-link {
    font-weight: 900;
    margin-top: 10vh;
    margin-bottom: 1.5rem;
    color: rgb(54, 105, 199);
    text-align: center;
    font-size: 1.3rem;
}

.zammit-footer-link-arrow {
    margin-right: 0.3rem;
    font-size: 1.5rem;
}

.zammit-unstyled-links {
    text-decoration: none
}