body, html {
    max-width: 100%;
    margin: auto;
    overflow-x: hidden;
    margin: 0;
    padding: 0;
    background-color: #fff;
}

.website-generic-font {
    /* font-family: 'Bai Jamjuree', sans-serif; */
    font-family: 'Almarai', sans-serif;

}

:root {
    color-scheme: only light;
  }