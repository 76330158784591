.text-white {
    color: #FFFFFF;
}

.downloadAppImg {
    width: 8.5rem;
    margin-left: 1rem;
    cursor: pointer;
}

.videoImg {
    width: 2rem;
    margin-left: 0.5rem;
}

.videoImg:hover {
    width: 2rem;
    cursor: pointer;
    transform: scale(1.2, 1.2);
    margin-left: 0.5rem;
}

.video-text {
    font-weight: normal;
    font-size: 0.9rem;
}

.video-container {
    /* margin: 0;
    padding: 0;
    margin-bottom: -0.5rem; */
    background: transparent!important;
    border: none!important;
    margin: 0;
    padding: 0;
    height: 20rem;
    width: 100%;
}

.video {
    width: 100%;
    height: 100%;
}

.clicked-icons-conainer {
    /* margin-bottom: 7rem; */
}



/* web/medium devices start */
@media (min-width: 768px) {
    .homeScreen-container {
        background-image: url("../../../public/images/homeScreenCoverimg.png");
        background-position: center center;
        background-repeat: no-repeat;
        min-height: 100%;
        width: 100%;
        /* background-size: cover; */
        text-align: center;
        vertical-align: middle;
        padding-top: 9rem;
        padding-bottom: 9rem;
        margin-top: 4rem; /*34an el navbar*/
    }

    .homeScreen-phrase1 {
        font-weight: 600;
        font-size: 2.9rem;
        margin-bottom: 1.3rem;
    }

    .homeScreen-phrase2 {
        font-weight: normal;
        font-size: 1.1rem;
        margin-bottom: 1.7rem;
        padding-left: 33vw;
        padding-right: 33vw;

    }

    .homeScreen-phrase3 {
        font-weight: normal;
        font-size: 1.1rem;
        margin-bottom: 3rem;
    }

    .scrollable-fixed-arrow {
        margin-top: 6rem;
        width: 3rem;
        position: fixed;
        cursor: pointer;
        z-index: 999;
    }

    .scrollable-fixed-arrow:hover {
        margin-top: 6rem;
        width: 3rem;
        position: fixed;
        cursor: pointer;
        z-index: 999;
        transform: scale(1.1, 1.1);
    }


}

/* web/medium devices start */


/* mobile devices start */
@media (max-width: 768px) {
    .homeScreen-container {
        background-image: url("../../../public/images/homeScreenCoverimg.png");
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
        min-height: 100%;
        width: 100%;
        vertical-align: middle;
        text-align: center;
        padding-top: 6rem;
        padding-bottom: 6rem;
        margin-bottom: -2rem;
        margin-top: 3rem; /*34an el navbar*/

    }

    .homeScreen-phrase1 {
        font-weight: 600;
        font-size: 1.6rem;
        margin-bottom: 1.3rem;
    }

    .homeScreen-phrase2 {
        font-weight: normal;
        font-size: 0.8rem;
        padding-left: 12vw;
        padding-right: 12vw;
        margin-bottom: 1.7rem;
    }

    .homeScreen-phrase3 {
        font-weight: normal;
        font-size: 0.8rem;
        margin-bottom: 3rem;
    }

    .scrollable-fixed-arrow {
        display: none;
    }



}

/* mobile devices end */