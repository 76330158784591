.companiesLogosCoverWebPage {
    direction: rtl;
    font-family: 'Almarai';
    font-weight: 500;
    line-height: 1.7;
    color: #2c2929;
}

.companies-logos-cover-grey-section {
    background: #F1F1F1 0% 0% no-repeat padding-box;
}

.companies-logos-cover-white-section {
    background: #FFFFFF 0% 0% no-repeat padding-box;
}

.Agel-logo-img-in-companies-logos-cover-page {
    width: 33%;
}

.companiesLogosCoverWebPageTitle {
    font-weight: 400;
    color: #00BD85;
    font-size: 1.5rem;
}

.apps-download-link-container {
    margin-top: -1rem;
}

.apple-app-store-logo-download {
    width: 24%;
    cursor: pointer;
}

.google-play-store-logo-download {
    width: 26%;
    margin-left: 0.5rem;
    cursor: pointer;
}


.companiesLogos-images-container {
    max-width: 50%;
}

.companies-logos-frame {
    border: 3px solid #00BD85;
    border-radius: 3px;
    height: 8rem;
    padding: 0.3rem;
    height: 8.5em;
    width: 100%;
}

.companiesLogos-images-col {
    padding-left: 0.2rem;
    padding-right: 0.2rem;
}

.redirect-btn-to-logos-page {
    border-style: none;
    outline-style: none;
    color: white;
    outline: none;
    background: transparent linear-gradient(261deg, #3DEBB8 0%, #14836396 100%) 0% 0% no-repeat padding-box;
    box-shadow: 5px 4px 8px #00000029;
    border: 4px solid #70707059;
    border-radius: 13px;
    opacity: 1;
    width: 18rem;
    height: 5rem;
    margin-top: 5em;
    margin-bottom: 5em;
    font-size: 1.3rem;
}

.redirect-btn-to-logos-page:hover {
    background: transparent linear-gradient(261deg, #299171 0%, #08382b96 100%) 0% 0% no-repeat padding-box;
}



/* mobile/medium devices start */
@media (max-width: 676px) {
    .companiesLogos-images-container {
        max-width: 88%;
    }

    .companies-logos-frame {
        border: 3px solid #00BD85;
        border-radius: 3px;
        height: 8rem;
        padding: 0.3rem;
        height: 4.5em;
        width: 100%;
    }
}

/* mobile/medium devices end */