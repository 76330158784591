.FQA {
    background: #F9F9F9 0% 0% no-repeat padding-box;
}

.FQA-container {
    padding-top: 6rem;
    padding-bottom: 6rem;
    /* padding-left: 7rem;
    padding-right: 7rem; */
}



/* web/medium devices start */
@media (min-width: 768px) {

    .FQA-title-container{
        margin-right: 7rem;
    }
    
    .FQA-title {
        font-size: 1.7rem;
        font-weight: 600;
        color: #1B1919;
    }

    .agel-carousel-container {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 1rem;
        margin-right: 1rem;
    }

    .agel-carousel-left {
        width: 50%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 777;
    }

    .agel-carousel-right {
        width: 40%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: -12%;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 0px 0px 12px #C4C4C429;
        border-radius: 60px;
        display: block;
        overflow: hidden;

    }

    .agel-carousel-img {
        width: 63%;
    }

    .white-bg-carousel{
        height: 63%;
    }

    .carousel-caption {
        top: 25%;
    }

    .carousel-question {
        font-size: 1rem;
        font-weight: 600;
        color: #6F6F6F;
    }

    .carousel-answer {
        font-size: 1.1rem;
        font-weight: 600;
        color: #242424;
        margin-top: 2rem;
    }
    
    .carousel-image{
        height: 26rem;
    }

    .carousel-indicators [data-bs-target] {
        box-sizing: content-box;
        flex: 0 1 auto;
        width: 30px;
        height: 3px;
        padding: 0;
        margin-right: 3px;
        margin-left: 3px;
        text-indent: -999px;
        cursor: pointer;
        background-color: #c81818;
        background-clip: padding-box;
        border: 0;
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
        opacity: .5;
        transition: opacity .6s ease;
        width: 0.5rem;
        height: 0.5rem;
        border-radius: 50%;
    }
    .carousel-indicators .active {
        opacity: 1;
    }
    
}

/* web/medium devices start */


/* mobile devices start */
@media (max-width: 768px) {

    .FQA-title-container{
        text-align: center;
    }
    
    .FQA-title {
        font-size: 1.3rem;
        font-weight: 600;
        color: #1B1919;
        text-align: center;
    }

    .agel-carousel-container {
        display: grid;
        justify-content: center;
        align-items: center;
        margin-left: 1rem;
        margin-right: 1rem;
    }

    .agel-carousel-left {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .agel-carousel-right {
        width: 100%;
        height: 100%;
        background-color: #303137;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 0px 0px 12px #C4C4C429;
        border-radius: 30px;
        display: block;
        overflow: hidden;
        z-index: 777;

    }

    .agel-carousel-img {
        width: 80%;
        margin-bottom: -12%;
    }

    .white-bg-carousel {
        /* height: 16rem; */
        max-height: 16rem;
    }

    .carousel-caption {
        top: 15%;
    }

    .carousel-question {
        font-size: 0.7rem;
        font-weight: 600;
        color: #6F6F6F;
    }

    .carousel-answer {
        font-size: 0.8rem;
        font-weight: 600;
        color: #242424;
        margin-top: 1.2rem;
    }

.carousel-indicators [data-bs-target] {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    padding: 0;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #c81818;
    background-clip: padding-box;
    border: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: .5;
    transition: opacity .6s ease;
    width: 0.4rem;
    height: 0.4rem;
    border-radius: 50%;
}
.carousel-indicators .active {
    opacity: 1;
}


}

/* mobile devices end */


/* special devices case */

@media (min-width:768px) and (max-width:1199px) {
    .white-bg-carousel {
        height: 28rem;
    }
    .carousel-caption {
        top: 18%;
    }
    .carousel-indicators [data-bs-target] {
        box-sizing: content-box;
        flex: 0 1 auto;
        width: 30px;
        height: 3px;
        padding: 0;
        margin-right: 3px;
        margin-left: 3px;
        text-indent: -999px;
        cursor: pointer;
        background-color: #c81818;
        background-clip: padding-box;
        border: 0;
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
        opacity: .5;
        transition: opacity .6s ease;
        width: 0.5rem;
        height: 0.5rem;
        border-radius: 50%;
    }
    .carousel-indicators .active {
        opacity: 1;
    }
    
}

/* @media all and (transform-3d), (-webkit-transform-3d) {
    .carousel-inner > .item.next,
    .carousel-inner > .item.active.right {
      left: 0;
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
    .carousel-inner > .item.prev,
    .carousel-inner > .item.active.left {
      left: 0;
      -webkit-transform: translate3d(100%, 0, 0);
      transform: translate3d(100%, 0, 0);
    }
  } */