.PaymentMethodsForRetailerWebPage {
    direction: rtl;
    font-family: 'Almarai';
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.7;
    color: #2c2929;
    text-align: justify;
}

.PaymentMethodsForRetailerWebPageTitle {
    font-weight: 600;
    font-size: 1.5rem;
    color: #00BD85;
}

.PaymentMethodsForRetailerWebPage .underline {
    border-bottom: 2px solid black;
    display: inline-block;
}

.Payment-methods-for-retailer-web-page-logo {
    width: 100%;
    height: 80%;
}

.PaymentMethodsForRetailerWebPage hr {
    opacity: 1;
    color: #00BD85;
    height: 2px;
}

.PaymentMethodsForRetailerWebPageFooter {
    color: #00BD85;
    text-align: justify;
    font-weight: 500;

}

.PaymentMethodsForRetailerWebPage li {
    padding-bottom: 0.5rem;
    padding-top: 0.5rem;
    /* list-style: arabic-indic; */
}

.PaymentMethodsForRetailerWebPage ol li {
    list-style: arabic-indic;
}

.PaymentMethodsForRetailerWebPage .vodafone-img,
.PaymentMethodsForRetailerWebPage .fawry-img,
.PaymentMethodsForRetailerWebPage .cib-img {
    width: 8rem;
}

.PaymentMethodsForRetailerWebPage .vodafone-text,
.PaymentMethodsForRetailerWebPage .fawry-text,
.PaymentMethodsForRetailerWebPage .cib-text {
    margin: 0 1rem 0  0;
}