.why-agel-container{
    background-color: #F9F9F9;
    padding-top: 6rem;
    padding-bottom: 6rem;
}

.why-agel-mini-images{
    width: 4rem;
    height: 4rem;
}

.why-agel-mini-container-box{
    background-color: #FFFFFF;
    /* padding-top: 1.5rem; */
    padding-bottom: 0.5rem;
    padding-left: 0.3rem;
    padding-right: 0.3rem;
    border-radius: 8px;
    box-shadow: 0px 3px 6px #C4C4C429;
    min-height: 100%;
    min-width: 100%;
}
.text-white{
    color: #FFFFFF;
}
.text-underline{
    text-decoration: underline;
}


/* laptops and up devices start */
@media (min-width: 768px) {

    .why-agel-title {
        font-weight: 600;
        color: #323232;
        font-size: 2.2rem;
    }

    .why-agel-subtitle{
        font-weight: normal;
        color: #393636;
        font-size: 0.9rem;
    }
    .why-agel-mini-container-box:hover {
        background: transparent linear-gradient(240deg, #00BD85 0%, #393636 100%) 0% 0% no-repeat padding-box;
        padding-left: 0.3rem;
        padding-right: 0.3rem;
        padding-bottom: 0.5rem;
        border-radius: 8px;
        box-shadow: 0px 3px 6px #C4C4C429;
        min-height: 100%;
        min-width: 100%;
    }        
    .why-agel-mini-box-title{
        color: #393636;
        font-weight: 600;
        font-size: 0.9rem;
    }    
    .why-agel-mini-box-subtitle{
        font-weight: normal;
        color: #393636;
        font-size: 0.8rem;
        line-height: 1.4rem;
        padding-left: 1rem;
        padding-right: 1rem;
    }
    
} 
/* web/medium devices start */


/* mobile/tablet devices start */
@media (max-width: 768px) {

    .why-agel-title {
        font-weight: 600;
        color: #323232;
        font-size: 2.1rem;
    }
    .why-agel-subtitle{
        font-weight: normal;
        color: #393636;
        font-size: 0.8rem;
    }
    .why-agel-mini-box-title{
        color: #393636;
        font-weight: 600;
        font-size: 0.9rem;
    }    
    .why-agel-mini-box-subtitle{
        font-weight: normal;
        color: #393636;
        font-size: 0.8rem;
        line-height: 1.5rem;
        padding-left: 1rem;
        padding-right: 1rem;
    }


}
/* mobile devices end */
